import { DateTime } from 'luxon'
import axios from 'axios'
import { currencyLocale } from '@/assets/js/data/currencies'
import { parseFloatToDecimal } from '@/util'

const timeZone = 'America/Bogota'
const DATE_FORMAT_LUXON = 'dd/MM/yyyy'
const TIME_FORMAT_LUXON = 'hh:mm a'

function formatToCurrencyBase (value, currencyCode = 'COP') {
  const nativeJavascriptFormatterToCurrency = new Intl.NumberFormat(currencyLocale[currencyCode].locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  return nativeJavascriptFormatterToCurrency.format(parseFloatToDecimal(value))
}

export function formatToCurrency (value, currencyCode = 'COP') {
  return (currencyCode !== 'COP' ? (currencyCode + ' ') : '') + formatToCurrencyBase(value, currencyCode)
}

export function formatToCurrencyWithoutCode (value, currencyCode = 'COP') {
  return formatToCurrencyBase(value, currencyCode)
}

export function formatToNumeric (value, locale = 'es-CO', decimals = 2) {
  const nativeJavascriptFormatterToNumeric = new Intl.NumberFormat(locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  })
  return nativeJavascriptFormatterToNumeric.format(parseFloatToDecimal(value, decimals))
}

/**
 * @param {import('@/models/common-address').default} address
*/
export function toAddress (address) {
  if (!address) return null

  const parts = []

  address.countryName && parts.push(address.countryName)
  address.regionName && parts.push(address.regionName)
  address.cityName && parts.push(address.cityName)
  address.addressNeighborhood && parts.push(address.addressNeighborhood)
  address.addressLine && parts.push(address.addressLine)

  return parts.join(', ')
}

/**
 * @param {string | Date | DateTime} value
 */
export function toDate (value) {
  if (typeof value === 'string') return DateTime.fromISO(value, { setZone: false }).toFormat(DATE_FORMAT_LUXON)
  if (value instanceof Date) return DateTime.fromJSDate(value).toFormat(DATE_FORMAT_LUXON)
  if (value instanceof DateTime) return value.toFormat(DATE_FORMAT_LUXON)

  return value
}

/**
 * @param {string | Date | DateTime} value
 */
export function toDateTime (value) {
  if (typeof value === 'string') return DateTime.fromISO(value, { setZone: false }).toFormat(`${DATE_FORMAT_LUXON} ${TIME_FORMAT_LUXON}`)
  if (value instanceof Date) return DateTime.fromJSDate(value).toFormat(`${DATE_FORMAT_LUXON} ${TIME_FORMAT_LUXON}`)
  if (value instanceof DateTime) return value.toFormat(`${DATE_FORMAT_LUXON} ${TIME_FORMAT_LUXON}`)

  return value
}

/**
 * @param {string | number} size
 */
export function toFileSize (size) {
  if (!size) return '0 bytes'
  if (typeof size === 'string') size = parseFloat(size)

  let unit = 'bytes'
  let value = size

  if (size < 1024) {
    unit = 'bytes'
    value = size
  } else if (size < 1024 ** 2) {
    unit = 'KB'
    value = size / 1024
  } else if (size < 1024 ** 3) {
    unit = 'MB'
    value = size / 1024 ** 2
  } else if (size < 1024 ** 4) {
    unit = 'GB'
    value = size / 1024 ** 3
  } else if (size < 1024 ** 5) {
    unit = 'TB'
    value = size / 1024 ** 4
  } else if (size < 1024 ** 6) {
    unit = 'PB'
    value = size / 1024 ** 5
  }

  // Si es decimal le aplica la función `toFixed(2)`
  value = value % 1 === 0 ? value : (Math.round(parseFloat(value) * 10 ** 2) / 10 ** 2)

  return `${value} ${unit}`
}

/**
 * @param {string} value
*/
export function formatToDate (value) {
  if (!value) return null
  return DateTime.fromISO(value, { setZone: timeZone }).toFormat(DATE_FORMAT_LUXON)
}

/**
 * @param {string} value
*/
export function formatToDateTime (value) {
  if (!value) return null
  return DateTime.fromISO(value, { setZone: timeZone }).toFormat(`${DATE_FORMAT_LUXON} ${TIME_FORMAT_LUXON}`)
}

// FECHA FORMATO yyyy-MM-dd
export function toISODate (value) {
  if (!value) return ''
  return DateTime.fromISO(value).toISODate()
}

export function addCommasToDecimal (nStr, decimals = 2) {
  nStr = parseFloatToDecimal(nStr, decimals).toString()
  const x = nStr.split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? '.' + x[1] : '.00'
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}

export function addCommasToDecimal6 (nStr) {
  return addCommasToDecimal(nStr, 6)
}

export function toDecimal2 (value) {
  // eslint-disable-next-line no-useless-escape
  let valueReplace = ReplaceData(value.toString().replace(/(?!^-)[^0-9\.]/g, ''), ',', '')
  const valuesSplice = valueReplace.split('.')

  if (valuesSplice.length > 1) {
    if (valuesSplice[1].length > 2) {
      valueReplace = valuesSplice[0] + '.' + valuesSplice[1].substr(0, 2)
    }
  }

  if (value !== valueReplace) {
    value = valueReplace
  }

  return value
}

export function ReplaceData (data, remp, cart) {
  if (data) {
    if (remp === '[' || remp === ']') { remp = '[' + remp + ']' }
    return data.toString().replace(new RegExp(remp, 'g'), cart)
  } else { return data }
}

export function toDecimal6 (value) {
  // eslint-disable-next-line no-useless-escape
  let valueReplace = ReplaceData(value.toString().replace(/(?!^-)[^0-9\.]/g, ''), ',', '')
  const valuesSplice = valueReplace.split('.')

  if (valuesSplice.length > 1) {
    if (valuesSplice[1].length > 6) {
      valueReplace = valuesSplice[0] + '.' + valuesSplice[1].substr(0, 6)
    }
  }

  if (value !== valueReplace) {
    value = valueReplace
  }

  return value
}

export function formatToMoney (value) {
  return window.accounting.formatMoney(value)
}

export function formatToMonthName (month) {
  if (month > 0) {
    if (month === 1) {
      return 'ENERO'
    } else if (month === 2) {
      return 'FEBRERO'
    } else if (month === 3) {
      return 'MARZO'
    } else if (month === 4) {
      return 'ABRIL'
    } else if (month === 5) {
      return 'MAYO'
    } else if (month === 6) {
      return 'JUNIO'
    } else if (month === 7) {
      return 'JULIO'
    } else if (month === 8) {
      return 'AGOSTO'
    } else if (month === 9) {
      return 'SEPTIEMBRE'
    } else if (month === 10) {
      return 'OCTUBRE'
    } else if (month === 11) {
      return 'NOVIEMBRE'
    } else if (month === 12) {
      return 'DICIEMBRE'
    }
  } else {
    return 'TODOS'
  }
}

/* VERIFICAR Y ELIMINAR */
export function formatTax (rateKind, rate) {
  if (rateKind === 'Porcentaje') return addCommasToDecimal(rate) + '%'
  else if (rateKind === 'PorMil') return addCommasToDecimal(rate) + '‰'
  return formatToCurrency(rate)
}

export function formatRateText (rateKind, rate) {
  if (rateKind === 'Porcentaje') return addCommasToDecimal(rate) + '%'
  else if (rateKind === 'PorMil') return addCommasToDecimal(rate) + '‰'
  return formatToCurrency(rate)
}

/**
 * Tarifa del impuesto o retencion calculada
 * Ejemplo: 19 > 0.19
 */
export function formatRateValue (rateKind, rate) {
  if (rateKind === 'Porcentaje') return rate / 100
  else if (rateKind === 'PorMil') return rate / 1000
  return rate
}

export async function getTrm () {
  const urlService = 'https://www.datos.gov.co/api/id/32sa-8pi3.json?$select=`valor`,`unidad`,`vigenciadesde`,`vigenciahasta`&$order=`vigenciahasta`+DESC&$limit=8&$offset=0'
  try {
    const { data: tasas } = await axios.get(urlService)
    return tasas
  } catch (error) {
    console.log(error)
  }
}
