import Cleave from 'cleave.js'

/**
 * Crea una instancia nueva de Cleave.js y la retorna
 *
 * @param {HTMLInputElement} el
 * @param {Object} opts
 */
export function makeCleaveInstance (el, opts) {
  /**
   * @param {Cleave} cleave
   * @param {HTMLInputElement} el
  **/
  let onInit = (cleave, el) => {
    // Prevenir que se envíe el valor sin procesar, solo aplica para los BInput simples
    el.addEventListener('input', e => {
      if (e.detail.fromCleaveDirective) {
        return
      }

      e.preventDefault()

      const event = new CustomEvent('input', {
        bubbles: true,
        cancelable: true,
        detail: { fromCleaveDirective: true }
      })

      el.value = cleave.properties.result
      el.dispatchEvent(event)
    })
  }

  opts = opts || {}

  if (opts.onInit) {
    onInit = opts.onInit
    delete opts.onInit
  }

  const cleave = new Cleave(el, opts)
  el.cleave = cleave
  onInit(cleave, el)

  return cleave
}

/** @type {import('vue').DirectiveOptions} */
export default {
  inserted (el, binding) {
    el = el.querySelector('input')
    const opts = binding.value || {}
    makeCleaveInstance(el, opts)
  },
  componentUpdated (el, binding, vnode) {
    setTimeout(() => {
      el = el.querySelector('input')

      // Si viene de un componente envoltorio como AppInputNumber,
      // solo actualizar el value del input
      if (binding.modifiers.fromWrapper) {
        el.value = el.cleave.properties.result
        return
      }

      if (vnode?.data.model) {
        el.cleave.onInput(vnode?.data.model.value || '')
      } else if (vnode?.componentOptions.propsData) {
        const { propsData } = vnode.componentOptions
        const value = propsData.value || propsData.modelValue || ''

        el.cleave.onInput(value.toString())
      }

      el.value = el.cleave.properties.result
      el.cleave.updateValueState()
    })
  }
}
